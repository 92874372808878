<template>
  <div>
    <div v-if="invalidLink" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('ACCOUNT.INVALID_LINK')}}</div>
    </div>

    <div v-if="no_access">
      <h3 style="text-align: center;">{{$t('ACCOUNT.NO_ACCESS_HEADER')}}</h3>
      <div class="mt-8 alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
      </div>
    </div>
    <div v-else>

      <b-card class="mb-5" v-if="!showForget && !invalidLink && !isLoading">
        <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.RESET_PASSWORD')}}</h3>
        <div class="text-center">{{$t('ACCOUNT.PASSWORD_WAS_CHANGED')}}</div>
        <hr>
        <router-link :to="login_link" tag="a" class="">{{$t('ACCOUNT.TO_LOGIN')}}</router-link>
      </b-card>
      <b-card class="forget-body mb-5" v-if="showForget && !invalidLink && !isLoading">
        <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.RESET_PASSWORD')}}</h3>
        <form class="forget-form">
          <b-alert
            v-if="form.email"
            show
            variant="light"
            >{{form.email}}</b-alert
          >

          <b-alert
            :show="showWarning"
            variant="danger"
            dismissible
            @dismissed="showWarning = false"
            fade
            >{{$t('ACCOUNT.PASSWORD_RULE_NOT_MET')}}</b-alert
          >
          <b-form-input
            type="password"
            v-model="form.password"
            class="mb-6 mt-4"
            :placeholder="$t('MEMBER.NEW_PASSWORD')"
          />
          
          <b-form-input type="password" v-model="form.confirmPassword" :placeholder="$t('MEMBER.CONFIRM_PASSWORD')" class="mb-12 mt-4" />

          <div class="d-flex align-items-center justify-content-end mb-12 mt-12">
            <b-button class="btn-login" variant="primary" @click="onSubmit">
              {{$t('MEMBER.RESET_PASSWORD')}}
            </b-button>
          </div>
        </form>
      </b-card>

    </div>
    
  </div>
</template>

<style lang="scss" scoped>
.forget-body {
  max-width: 500px;
  width: 100%;
  .forget-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import store from '@/core/services/store';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_USER_ID, SET_COMPANY_ID } from '@/core/services/store/common.module';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';


export default {
  mixins: [ validationMixin, toasts ],
  name: 'forget',
  data() {
    return {
      isLoading: true,
      invalidLink: false,
      showForget: true,
      // Remove this dummy login info
      form: {
        email: '',
        confirmPassword: '',
        password: ''
      },
      no_access: false,
      company_id: null,
      token: null,
      showWarning: false
    };
  },
  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs('password') }
    }
  },
  async mounted() {
    var token = this.$route.query.token;

    if (token === null || token === undefined || token.length < 8) {
      token = this.$route.params.token;
    }

    this.token = token;

    this.getUserByToken(token);

    const data = await this.get_public_data();

    this.company_id = data.sid;
  },
  methods: {
    async get_public_data() {
      try {
        const res = await axios.get(`/company/public-data`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('get_public_data', err);
      }

      return null;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getUserByToken(token) {
      const loader = this.$loading.show();

      axios
        .get(`/user/resettoken?token=${token}`)
        .then(res => {
          if (res.status === 200) {
            this.invalidLink = false;
            this.isLoading = false;
            this.form.email = res.data.user.email;
          }
          else {
            this.invalidLink = true;
          }
          
          loader & loader.hide();
          // this.showForm = true;
        })
        .catch(err => {
          console.error(err);
          this.invalidLink = true;
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta användardata');

          loader & loader.hide();
        });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showWarning = true;
        return;
      }
      const email = this.form.email;
      const password = this.form.password;
      
      // show loading
      const loader = this.$loading.show();
      const res = await axios.post('/user/reset', { email, password, token: this.token });
      
      setKeyValue('user_login_email', email);

      if (res.status === 200) {
        // success
        if (res.data.selected_company_id) {
          this.$store.dispatch(SET_COMPANY_ID, res.data.selected_company_id);
        }

        this.$store.dispatch(LOGIN, {
          user: {
            user_id: res.data.id,
            email: email
          },
          token: res.data.token,
          refresh_token: res.data.refresh_token.refresh_token,
          expires_ts: res.data.refresh_token.expires_ts
        });

        loader.hide();

        this.$router.push({ name: 'ml-auth-forward' });

      }
      else if (res.status === 403) {
        this.no_access = true;
      }
      else if (res.status === 406) {
        
      }
      
      loader & loader.hide();
    },
    
  },
  computed: {
    login_link() {
      return `/ml-login`;
    },
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
